import * as React from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import Layout from '../components/layout';
import SEO from '../components/seo';
import CourseList from '../components/CourseList';
import CourseListEmpty from '../components/CourseListEmpty';
import '../assets/scss/search-page.scss';
import {} from '../state/actions';
import { httpPost } from '../config/fetch';
import { getCourseList } from '../config/api';
import { parse } from 'qs';
const SearchPage = ({ location, userCourseList }) => {
  const [courseList, setCourseList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [pageInfo] = React.useState({
    pageNumber: 1,
    pageSize: 3
  });
  const { keyword } = parse(location.search.split('?')[1]);
  React.useEffect(() => {
    const getData = () => {
      const params = {
        ...pageInfo,
        keyword
      };
      setLoading(true);
      httpPost(getCourseList, params)
        .then(res => {
          const { resCode, courseList } = res;
          if (resCode === 0) {
            setCourseList(courseList);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setCourseList([]);
        });
    };
    getData();
  }, [pageInfo, keyword]);
  return (
    <Layout>
      <SEO title="搜索" />
      <div className="search-page-wrapper">
        <div className="container">
          <Spin spinning={loading} size="large">
            {courseList.length > 0 ? (
              <CourseList list={courseList} userCourseList={userCourseList}/>
            ) : (
              <CourseListEmpty />
            )}
          </Spin>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    userCourseList: state.userCourseList
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);
